import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { dark, height, width } = this.props;
    return (
      <svg
        className={cx("Icon", { "text-brand": !dark }, { "text-white": dark })}
        viewBox="0 0 717 405"
        width={width || "auto"}
        height={height || "auto"}
        fill="currentcolor"
        data-testid="main-logo"
      >
        <path fill="#000000" opacity="0" stroke="none"
          d="
        M526.000000,406.000000
          C352.166656,406.000000 178.833313,405.985901 5.500058,406.093811
          C1.750690,406.096130 0.902581,405.249451 0.905783,401.499908
          C1.018507,269.500031 1.018507,137.499985 0.905783,5.500081
          C0.902581,1.750540 1.750809,0.904943 5.500041,0.906592
          C241.500000,1.010387 477.500000,1.010387 713.499939,0.906592
          C717.249207,0.904943 718.097473,1.750540 718.094238,5.500081
          C717.981506,137.499985 717.981506,269.500031 718.094238,401.499908
          C718.097412,405.249451 717.250305,406.104248 713.499817,406.096130
          C651.166748,405.961212 588.833313,406.000000 526.000000,406.000000
        z"/>
        <path fill="#264C28" opacity="1.000000" stroke="none"
          d="
        M493.433716,32.422890
          C507.092865,45.568630 520.638367,58.660583 533.598389,72.359993
          C546.426758,85.920235 560.588257,98.232841 573.220398,111.961884
          C590.415955,130.650620 608.655579,148.276703 626.426086,166.378662
          C636.479309,176.619354 646.709351,186.686249 656.776306,196.913574
          C664.362244,204.620285 671.731262,212.541168 679.349060,220.215515
          C688.945435,229.883102 698.659546,239.435089 708.403198,248.954666
          C711.504028,251.984192 711.493835,254.305420 707.690186,256.325958
          C696.291992,262.380890 684.760620,268.202576 673.390625,274.288361
          C659.414246,281.769135 645.241150,288.907074 631.157654,296.134430
          C616.636597,303.586334 602.187012,311.263519 587.428162,318.307190
          C578.514160,322.561340 570.006836,327.938110 561.134827,332.165985
          C550.188904,337.382233 539.592224,343.269958 528.632935,348.447510
          C517.859619,353.537201 507.468689,359.439575 496.952362,365.064545
          C495.866913,365.645172 494.837341,365.956085 493.276428,365.910736
          C491.368652,364.168396 491.515869,361.987732 491.483643,360.012909
          C491.423950,356.353088 491.475159,352.684662 491.496948,349.019989
          C492.101349,247.369141 489.767365,145.727829 490.508881,44.076595
          C490.537842,40.107704 489.992950,35.986618 493.433716,32.422890
        z"/>
        <path fill="#226231" opacity="1.000000" stroke="none"
          d="
        M170.586533,356.372101
          C155.826248,348.935211 141.643753,341.407745 127.245193,334.319153
          C118.303970,329.917267 109.558891,325.125275 100.665428,320.643250
          C89.449783,314.990875 78.430069,308.891541 67.204384,303.202759
          C56.476208,297.766113 46.119545,291.566559 35.239597,286.470978
          C29.250868,283.666138 23.943983,279.603699 17.483007,277.614563
          C13.974744,276.534485 12.906733,273.178101 12.916240,269.216797
          C13.043361,216.252640 12.998561,163.288055 13.001184,110.323570
          C13.001507,103.828094 13.028858,97.332619 13.110590,90.366074
          C15.165347,87.615601 17.416538,87.963310 19.891460,89.047935
          C29.206020,93.130020 38.004967,98.238853 47.038464,102.860840
          C77.881851,118.641869 108.574417,134.717911 139.435532,150.468918
          C149.817871,155.767914 160.190323,161.103195 170.472458,166.603455
          C172.080658,167.463745 173.849030,168.117737 174.804688,170.399506
          C175.590500,172.571228 175.485291,174.414185 175.485779,176.237701
          C175.500916,233.631912 175.520462,291.026123 175.471863,348.420319
          C175.468948,351.851074 176.116638,355.743835 170.586533,356.372101
        z"/>
        <path fill="#3FA248" opacity="1.000000" stroke="none"
          d="
        M173.507751,170.443665
          C164.441818,167.359711 156.690887,162.409363 148.539551,158.322617
          C130.118393,149.086945 111.886963,139.473007 93.572693,130.023926
          C69.055954,117.374741 44.530910,104.741623 20.019682,92.081772
          C18.079918,91.079895 16.104364,90.256912 13.552109,89.938217
          C12.528028,87.217194 14.878500,86.103027 16.357185,85.501068
          C21.973974,83.214531 27.713839,80.686043 32.653698,77.611320
          C41.025459,72.400459 50.543297,69.516266 58.570702,63.641827
          C61.268948,61.667252 65.299606,61.025814 68.538445,59.286503
          C74.285965,56.199986 80.331963,53.585648 85.745651,50.006275
          C87.654892,48.743942 89.759819,48.665226 91.679977,47.575260
          C100.101730,42.794724 109.601921,39.816521 117.718269,34.630894
          C122.845924,31.354784 128.321777,28.351553 133.580917,25.854017
          C140.236023,22.693546 146.832458,19.295143 153.340393,15.874084
          C159.088898,12.852238 165.997833,11.846748 170.497177,6.466668
          C171.253830,5.561901 172.982620,5.638719 173.699768,6.489977
          C176.729370,10.086110 181.404724,10.565684 185.168045,12.760604
          C192.280731,16.909019 199.586609,20.834658 207.141052,24.089691
          C216.477905,28.112728 225.403625,32.986168 234.512253,37.414280
          C241.341370,40.734226 248.155792,44.383762 255.026352,47.670048
          C261.075928,50.563641 267.347534,53.427856 273.039001,56.666176
          C282.295135,61.932709 292.024872,66.123367 301.405975,71.044876
          C310.962860,76.058594 320.288452,81.611404 330.499908,85.327965
          C331.157501,85.567307 331.587830,86.430946 332.488098,87.070084
          C335.989502,90.333397 333.969604,92.249771 331.069885,93.695724
          C314.384827,102.015701 297.826874,110.588043 281.111023,118.846245
          C252.305893,133.076950 223.832275,147.964645 195.058685,162.255341
          C189.854721,164.839920 184.858307,167.824493 179.534744,170.160690
          C177.713333,170.959991 175.853668,171.868729 173.507751,170.443665
        z"/>
        <path fill="#3FA148" opacity="1.000000" stroke="none"
          d="
        M493.474731,32.151794
          C492.847229,34.722244 492.895233,37.399666 492.894806,40.060139
          C492.886414,91.825104 492.119476,143.604691 493.103333,195.350952
          C494.106415,248.109512 493.264679,300.855133 493.735229,353.603851
          C493.769257,357.415405 493.289703,361.231567 492.971100,365.449890
          C487.310181,364.010132 482.834656,360.106934 476.945099,358.342834
          C472.190308,356.918671 466.948303,354.289886 462.799316,351.549103
          C457.180847,347.837555 450.248932,346.762970 445.010681,341.884644
          C444.109741,341.145325 443.525177,340.540436 442.831482,339.492065
          C441.345612,334.374542 445.515991,331.240387 446.360321,326.708771
          C447.706207,322.781433 449.966888,319.628143 450.860657,315.550842
          C451.061432,314.789581 451.166443,314.351318 451.326965,313.576782
          C451.562592,311.873474 451.935211,310.564880 452.699280,309.019257
          C453.077454,308.067108 453.289062,307.419891 453.455536,306.404907
          C453.641876,304.188721 454.438934,302.481842 454.971191,300.321838
          C455.324951,298.950562 455.507263,297.928558 455.559448,296.471283
          C455.767059,291.572754 459.695190,288.562531 459.830353,283.759491
          C460.029541,276.682495 461.245056,269.701202 456.635162,263.432373
          C455.646240,262.087555 455.576569,260.336670 455.524872,258.257874
          C455.465485,256.773499 455.416168,255.702774 455.262848,254.267105
          C455.778320,251.872055 455.533569,250.042725 453.801605,248.212219
          C453.556427,247.166458 453.423096,246.460709 453.097046,245.431458
          C452.510712,243.739365 451.902893,242.435532 451.533844,240.677612
          C451.355438,239.621338 451.222473,238.920425 450.926025,237.890976
          C449.720062,231.102844 448.246826,224.800873 445.037537,218.976822
          C443.577606,216.327393 442.072205,213.990936 438.767334,212.853973
          C438.387238,212.390503 438.237640,212.205917 437.862732,211.750244
          C436.764160,210.141022 435.839905,208.835297 434.946472,207.111481
          C434.632507,206.130783 434.587921,205.420135 433.887878,204.600952
          C433.264343,204.026245 432.893616,203.705734 432.192139,203.198669
          C431.642883,202.892014 431.420105,202.776810 430.907043,202.419830
          C428.733643,199.907272 430.548035,196.721619 428.068909,194.168106
          C425.965881,192.001923 424.239258,190.515610 420.931274,190.489838
          C420.407379,190.103394 420.211395,189.932419 419.707825,189.515076
          C417.881104,184.634949 415.696899,180.749390 409.511169,180.873352
          C408.811951,180.749100 408.553284,180.621323 407.983734,180.198715
          C406.750153,177.852280 407.307343,174.681778 403.347717,174.560974
          C402.757385,174.381805 402.524414,174.297791 401.985352,174.001678
          C400.287659,171.778473 396.894196,173.375458 395.730530,170.249969
          C392.724854,166.531479 388.579132,165.434280 384.759766,164.094528
          C379.211578,162.148300 373.942535,159.329300 368.047058,158.403885
          C365.931061,158.071732 365.257751,156.895355 366.177460,154.522461
          C369.736237,151.102264 372.436096,147.330490 375.750580,144.360062
          C380.900116,139.745056 385.759460,134.852646 390.547302,129.917297
          C398.364838,121.858871 406.809418,114.439941 414.857635,106.646782
          C420.188782,101.484581 425.018494,95.567741 431.415894,91.252907
          C434.266296,89.330376 436.935089,86.036728 439.337677,82.941071
          C442.812256,78.464226 447.879089,75.264389 451.753235,71.048012
          C460.152344,61.906864 469.508301,53.731857 477.916687,44.559425
          C482.127655,39.965805 486.997467,34.970581 493.474731,32.151794
        z"/>
        <path fill="#1A2D1A" opacity="1.000000" stroke="none"
          d="
        M173.161377,170.712296
          C224.766891,144.274902 276.369141,118.300514 327.932312,92.248772
          C329.862000,91.273827 332.575775,90.815201 332.839844,87.483620
          C336.619202,87.902122 335.959259,90.938103 335.962585,93.474205
          C335.987244,112.354736 335.942963,131.235352 335.888916,150.587357
          C336.009338,153.945572 334.848450,155.566422 331.752594,155.585464
          C329.822479,155.597336 327.919250,156.045700 325.591888,156.448151
          C322.924866,156.373001 320.660248,156.984619 318.025055,156.619110
          C311.776154,157.986557 305.461639,157.935959 300.646423,163.106659
          C299.580170,163.596451 298.873291,163.919830 297.819275,164.370972
          C296.613983,164.948212 295.702515,165.226868 294.391602,165.321533
          C293.153564,165.272095 292.307526,164.712738 291.085632,165.243439
          C289.332794,165.989670 289.583221,167.777679 288.191742,168.823334
          C287.640869,169.100174 287.414917,169.193466 286.844238,169.388992
          C284.726044,169.948730 283.282318,171.486786 280.905701,171.034149
          C280.336304,170.924042 280.110413,170.872925 279.539734,170.867401
          C274.473907,170.985901 273.125763,175.098923 270.475891,178.206116
          C270.042511,178.606903 269.874481,178.772186 269.470947,179.207123
          C267.859619,180.761414 265.471497,180.774918 264.551056,183.272736
          C264.027191,183.678680 263.784027,183.795715 263.147736,183.934784
          C261.611267,183.810287 260.412018,183.122238 259.230591,184.606247
          C258.553986,185.679016 258.399078,186.686478 257.186279,187.349014
          C250.459915,191.122131 247.005402,197.614670 241.912262,202.982483
          C241.369919,204.381821 240.513336,205.259262 240.195099,206.765869
          C239.849258,207.718277 239.580978,208.645447 239.085327,208.724060
          C234.982986,209.374817 235.329498,212.623840 234.754791,215.776886
          C234.032974,216.599915 233.417038,217.001892 232.749573,217.846725
          C230.846954,220.563446 229.349762,223.102600 228.063385,226.135315
          C227.311050,227.054962 226.954636,227.832581 226.425613,228.863800
          C226.057999,229.707062 225.835953,230.276001 225.573212,231.151398
          C225.338654,232.023438 225.123199,232.588165 225.034180,233.497772
          C224.991501,234.233795 224.841446,234.610504 224.350037,235.156311
          C224.029922,235.547180 223.908676,235.707977 223.619781,236.119019
          C223.236847,236.711517 223.031219,237.059479 222.717163,237.696320
          C222.523270,238.393433 222.476028,238.810013 222.388031,239.549164
          C221.760590,241.336075 221.566452,242.856674 221.675415,244.754059
          C221.627609,245.544434 221.578552,246.001373 221.501602,246.816360
          C221.287384,248.653824 221.144989,250.151993 219.308502,251.175964
          C218.937836,251.650452 218.813736,251.859528 218.545929,252.400208
          C217.571457,253.706055 217.790665,254.984055 217.390930,256.472992
          C217.044327,259.499573 215.436111,261.858551 215.590088,264.981262
          C215.328857,266.320618 215.945740,267.266174 215.749634,268.613220
          C216.087372,269.973206 216.440399,270.918610 217.225037,272.101837
          C217.751022,272.891846 217.853134,273.400421 217.728500,274.350708
          C217.610153,275.846008 217.592575,276.937378 217.804932,278.453705
          C217.966858,279.458069 218.030228,280.038879 218.031403,281.075073
          C216.252258,284.324493 214.668930,287.260803 215.837692,290.637115
          C216.484329,292.505096 219.637772,292.803802 219.335464,295.963745
          C219.384064,297.892303 219.538666,299.356384 220.266449,301.164734
          C219.975769,306.930328 219.782959,312.221161 222.368301,317.245087
          C222.949997,318.375488 223.299545,319.567505 224.108414,320.659424
          C226.965912,324.516876 228.052704,328.749146 225.639465,333.542145
          C218.318710,333.835541 213.093872,338.358856 207.174957,341.023499
          C197.419525,345.415405 187.912354,350.363739 178.348953,355.173859
          C175.894043,356.408569 173.468323,357.195282 170.412994,356.426208
          C173.354782,354.228790 172.999542,350.929657 173.000687,347.784546
          C173.012787,314.521851 172.992584,281.259155 173.016800,247.996445
          C173.035431,222.385010 173.104736,196.773621 173.161377,170.712296
        z"/>
        <path fill="#7E3135" opacity="1.000000" stroke="none"
          d="
        M458.877625,296.345551
          C458.613953,297.945038 458.513184,299.108185 458.276855,300.689575
          C458.266113,302.922943 457.076263,304.401215 456.713928,306.422913
          C456.487274,307.559021 456.401947,308.399902 456.181946,309.643311
          C455.756409,311.383148 454.951080,312.498505 454.351379,314.070129
          C454.246338,314.772430 454.241425,315.150330 454.149506,315.799377
          C453.187408,319.958923 451.847046,323.646210 449.338013,327.221375
          C448.460938,331.540436 445.858215,334.690857 444.265015,338.734283
          C443.941772,339.926025 444.204010,340.715973 444.047668,341.656647
          C439.773590,351.722473 432.287750,358.923065 424.853027,366.209686
          C419.028687,371.918091 413.183289,377.661682 406.008057,381.569733
          C399.363556,385.188629 393.039246,389.323242 385.945190,392.295898
          C379.972717,394.798553 373.926422,397.511200 367.743835,398.202972
          C361.122040,398.943909 355.412384,402.981842 348.395996,402.136230
          C342.167816,401.385590 335.767822,402.196320 329.462921,401.903168
          C324.577026,401.675964 319.886597,402.493286 314.787476,399.679230
          C311.289612,397.748871 305.740143,397.774750 301.094940,397.006409
          C300.009796,396.826935 298.690674,397.276611 298.008728,395.442078
          C297.119263,392.499420 298.748047,391.973267 300.723907,391.519348
          C305.281372,390.472351 309.957520,390.391174 314.556244,389.658813
          C339.891998,385.624023 363.138000,376.737610 384.353363,361.972168
          C399.908569,351.146118 412.649719,337.738068 423.485504,322.587097
          C436.722778,304.078339 444.670135,283.127136 448.464325,260.655731
          C448.851837,258.360596 449.085266,256.004303 449.748505,253.801575
          C450.580994,251.036880 452.028748,248.569550 456.081390,249.529938
          C457.598206,250.098190 458.010376,251.003189 458.034912,252.567871
          C458.253632,253.864014 458.414276,254.766541 458.494080,256.006775
          C461.216187,269.196350 462.056976,282.063293 458.748108,294.971313
          C458.678925,295.241211 458.935730,295.594696 458.877625,296.345551
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M216.768494,302.347382
          C216.370224,300.613220 216.148010,299.231964 215.955750,297.429565
          C216.265213,293.194885 215.219116,289.511383 214.960388,285.355103
          C219.120850,281.850555 221.402603,281.599854 224.272705,285.499695
          C229.398682,292.464844 236.119522,297.466187 242.689835,302.857452
          C254.338440,312.415741 267.826202,317.402802 282.338013,319.164246
          C304.642090,321.871521 325.476837,316.976562 343.819122,303.628448
          C365.705017,287.701538 377.765778,266.121552 379.428375,239.009567
          C380.643158,219.199448 375.146545,201.384369 364.069031,184.822678
          C357.674896,175.263000 349.953857,167.710342 340.170197,161.974136
          C335.397766,159.176010 335.236847,158.370880 337.709839,152.400558
          C342.054443,152.094681 346.115082,152.143188 350.166107,151.968430
          C352.232819,151.879288 353.846436,152.508026 355.605286,154.071274
          C379.118011,171.166733 391.925354,194.595001 395.129944,222.479736
          C398.461273,251.467377 390.007172,277.683685 370.941742,300.211914
          C352.830841,321.612244 329.508301,332.703979 301.953339,335.523132
          C276.938202,338.082458 254.615448,330.732910 234.138657,316.785004
          C232.477631,315.653564 231.150864,314.129913 229.591812,312.882507
          C227.054977,310.852783 224.449921,308.939880 220.512634,309.405701
          C217.336243,307.973053 218.701279,304.593323 216.768494,302.347382
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M216.573242,302.365479
          C218.569473,303.254120 218.942383,305.445251 219.859192,307.706360
          C225.182190,310.958649 230.277008,314.075409 235.162262,317.491882
          C250.242249,328.037842 266.910309,333.842865 285.237061,335.632751
          C309.577362,338.009949 331.608154,331.357208 351.349945,317.979553
          C378.034851,299.897003 392.452209,273.885803 395.443390,241.904282
          C397.958496,215.013092 389.503723,191.191299 373.063385,170.069855
          C371.131439,167.587814 369.915863,165.414078 372.036743,162.205505
          C373.518036,161.333664 374.841675,161.154739 376.522034,160.725082
          C383.109772,163.324524 390.023712,164.487274 395.318298,169.668030
          C394.761108,174.746979 396.358521,178.848572 398.378357,182.963562
          C415.491577,217.828629 415.218323,253.130371 397.431519,287.131256
          C378.698730,322.940582 348.521454,344.345825 307.887665,349.636993
          C283.051971,352.871033 259.534546,348.433533 237.572189,336.311188
          C234.233749,334.468475 230.994659,334.516815 227.222000,335.241333
          C226.391800,334.735535 225.888489,334.422363 225.327637,333.901245
          C226.192505,329.431549 223.919815,325.899170 222.444626,322.269775
          C219.866531,315.926910 217.887421,309.437714 216.573242,302.365479
        z"/>
        <path fill="#863035" opacity="1.000000" stroke="none"
          d="
        M456.298462,250.761429
          C453.129791,252.093857 452.220978,254.502289 451.808411,257.272888
          C448.295654,280.863953 440.161377,302.783234 426.878021,322.551666
          C420.393005,332.202759 412.762421,341.116455 404.239166,349.127686
          C392.430115,360.227295 379.482544,369.648163 364.794128,376.754791
          C347.371643,385.184174 329.200134,390.720276 309.995605,392.986023
          C306.200317,393.433838 302.442291,394.197327 298.284851,394.936951
          C293.407288,396.707489 290.609924,392.622375 286.869690,391.610016
          C280.987732,390.017975 275.959290,386.193207 270.361145,382.949585
          C268.248657,380.347687 269.398163,379.059937 271.951019,378.692505
          C274.588501,378.312866 277.262573,378.433167 279.921631,378.718323
          C302.708435,381.161957 324.712311,377.726257 346.144989,369.928162
          C367.405396,362.192719 385.327850,349.315247 400.624115,333.013580
          C415.732330,316.912323 426.117828,297.884186 432.754578,276.705444
          C437.643127,261.105377 440.057343,245.309296 439.508331,229.007935
          C439.380432,225.211761 440.210175,221.954483 445.329224,221.617920
          C449.957733,223.220657 449.650391,227.767639 451.499603,230.908249
          C452.424988,232.479904 453.118134,234.349045 453.890778,236.498550
          C454.016907,237.560898 454.151276,238.215820 454.263611,239.206299
          C454.583221,240.909943 455.725159,241.900116 456.055481,243.639557
          C456.262024,244.796707 456.426361,245.607803 456.606781,246.833801
          C456.830566,248.357300 457.773102,249.467560 456.298462,250.761429
        z"/>
        <path fill="#B32934" opacity="1.000000" stroke="none"
          d="
        M337.671814,152.072449
          C337.748169,156.047653 339.575867,158.418167 342.882294,160.593567
          C368.079468,177.171371 380.375732,201.011078 382.038757,230.820786
          C382.856323,245.474884 380.063568,259.496460 373.764191,272.512451
          C365.996155,288.563110 354.046478,301.141876 338.614014,310.342072
          C321.174713,320.738770 302.168854,323.906891 282.372620,321.785187
          C267.660858,320.208405 253.974518,315.276733 242.115936,305.800110
          C235.099854,300.193298 227.611221,295.229614 222.599075,287.490417
          C221.356476,285.571716 219.040970,285.159882 216.429779,284.994324
          C213.874573,284.042450 215.566452,281.956177 214.563843,280.308868
          C214.264511,279.550201 214.140366,279.110901 214.020233,278.327911
          C214.001434,277.194336 213.995728,276.404388 214.032043,275.318420
          C214.089127,274.567780 214.132462,274.113068 214.291336,273.334259
          C215.102249,272.127045 214.812454,270.841888 216.020294,269.811615
          C216.703781,269.546021 216.998459,269.478088 217.739914,269.323486
          C222.040329,268.214996 223.923965,271.233948 225.147064,273.602356
          C232.481735,287.805267 243.923843,297.330475 257.837677,304.716095
          C267.577057,309.885864 277.999939,311.804718 288.566528,313.426300
          C297.708008,314.829193 306.635376,312.384796 315.307770,310.077637
          C332.577240,305.483337 346.501495,295.516693 357.339783,281.338013
          C367.479340,268.073456 373.048584,252.783554 373.200592,236.338394
          C373.422607,212.316757 364.906189,191.419220 346.692383,175.032440
          C341.778656,170.611588 336.343231,166.975647 330.639648,163.654678
          C327.045868,161.562195 327.172302,159.989349 330.918091,156.475494
          C332.437347,154.714127 335.077820,155.014481 335.765442,152.294128
          C336.459625,151.978592 336.902710,151.992462 337.671814,152.072449
        z"/>
        <path fill="#8C2F35" opacity="1.000000" stroke="none"
          d="
        M446.356171,222.731445
          C442.092682,225.020416 441.885406,228.663864 442.008118,232.436661
          C442.859283,258.606262 435.660065,282.741913 423.467102,305.598145
          C411.442230,328.139282 394.157196,345.730042 372.747498,359.463226
          C343.841125,378.005157 312.127045,384.252594 278.265869,381.273712
          C275.618683,381.040863 272.927307,380.633759 270.284241,382.630066
          C265.587006,378.869995 259.831818,376.565704 255.999573,371.437256
          C254.636810,369.136230 255.227844,368.039642 257.492310,367.689117
          C259.354309,367.400879 261.130402,367.846222 262.918152,368.266388
          C283.708405,373.152496 304.493744,372.991974 325.244049,368.067657
          C347.192322,362.859039 366.902222,353.137756 383.867279,338.204529
          C408.624146,316.412689 424.170380,289.329559 429.704163,256.719940
          C432.086853,242.679108 432.328552,228.560730 429.968048,214.464157
          C429.551941,211.979340 429.464355,209.502182 429.578491,206.999619
          C429.686859,204.622925 430.814240,203.010056 433.439606,202.124756
          C434.353607,202.025330 434.831940,202.180588 435.497192,202.807236
          C436.121185,203.499329 436.503723,203.892181 437.000763,204.536530
          C438.227051,206.346329 439.609344,207.722946 440.457275,209.841171
          C440.598175,210.129150 440.747589,210.216263 440.785248,210.294098
          C442.974976,214.325348 446.430023,217.648132 446.356171,222.731445
        z"/>
        <path fill="#9A2E35" opacity="1.000000" stroke="none"
          d="
        M236.426544,350.271118
          C232.328247,347.097870 230.258438,342.927704 228.033752,338.464539
          C227.827591,337.902740 227.695419,337.692291 227.649246,337.120789
          C229.950043,333.839020 232.550125,334.860382 235.042984,336.173920
          C246.767670,342.351715 259.190735,346.440521 272.134949,349.204681
          C286.198792,352.207916 300.221466,352.026764 314.280457,349.495392
          C334.332764,345.884857 352.411682,337.915314 368.211151,324.938538
          C381.134521,314.324066 391.204315,301.521484 398.733093,286.552094
          C406.119629,271.865540 410.071289,256.251892 411.135590,240.060089
          C412.366943,221.326508 408.529114,203.288849 400.765594,186.151382
          C399.874237,184.183716 398.635834,182.361099 398.024475,180.275696
          C396.852478,176.277725 397.793488,174.870255 402.576294,173.853226
          C405.707855,173.994705 407.436768,176.027893 409.933594,177.503494
          C410.305847,177.709000 410.243652,177.727829 410.253174,177.696762
          C411.838531,179.680725 414.290649,180.645309 416.176941,182.845337
          C414.899078,186.698868 416.009247,190.034927 417.049164,193.307159
          C421.729889,208.035034 424.241486,223.135406 423.408203,238.549545
          C422.149567,261.831085 416.190247,283.749237 403.308411,303.570190
          C393.454834,318.731659 381.307709,331.719421 366.282013,341.558197
          C349.441437,352.585419 331.092133,360.238617 310.765839,362.599884
          C286.218781,365.451447 262.652985,362.355011 240.131943,352.048370
          C238.991531,351.526459 237.624146,351.516296 236.426544,350.271118
        z"/>
        <path fill="#952E35" opacity="1.000000" stroke="none"
          d="
        M236.129089,350.347900
          C238.685791,349.283234 241.028519,349.705475 243.441833,350.711121
          C274.277435,363.560730 305.665070,364.838776 337.012360,353.418579
          C363.175262,343.887146 384.208405,327.387878 399.857391,303.972260
          C414.241150,282.449860 420.403839,258.635376 420.856903,233.220901
          C421.113312,218.839645 418.371216,204.721725 413.307953,191.173157
          C412.208801,188.232040 412.101257,185.683319 414.779785,183.169510
          C418.323059,182.037323 418.738647,186.051651 421.472351,186.866272
          C421.731079,187.157578 421.831451,187.187515 421.847565,187.237366
          C424.398438,189.476303 427.091156,191.525940 428.416504,195.174606
          C426.426636,199.611511 427.171722,203.785431 428.002350,207.815750
          C434.833893,240.964050 428.614807,272.148590 412.878662,301.461823
          C407.109558,312.208435 399.163147,321.683868 390.447205,330.601379
          C377.338104,344.013641 361.863647,353.433441 344.945129,360.577545
          C331.430695,366.284180 317.192688,369.996948 302.369812,370.465729
          C288.200134,370.913818 274.075806,370.643860 260.267975,366.700073
          C255.337891,365.291931 250.176010,364.533447 246.369751,360.642181
          C245.755585,360.014343 245.177170,360.386353 244.192139,360.225769
          C240.209396,357.808441 238.494247,353.972931 236.129089,350.347900
        z"/>
        <path fill="#9E2D35" opacity="1.000000" stroke="none"
          d="
        M403.495483,174.591797
          C399.780731,176.644318 400.221710,179.270142 401.644196,182.078781
          C413.850189,206.178452 416.837555,231.635788 411.462372,257.847046
          C407.011810,279.549713 397.376343,298.898346 382.171753,315.379364
          C358.316498,341.237305 328.316223,353.471619 293.791351,354.195557
          C274.325409,354.603729 255.585892,349.403137 238.052841,340.574036
          C235.080200,339.077087 232.197708,337.361969 228.385956,337.041962
          C227.861893,337.154755 227.722610,337.307556 227.374115,337.231415
          C227.101044,336.569092 227.037216,336.135651 226.934235,335.375458
          C230.146820,329.503540 231.040161,329.396515 236.644836,332.619324
          C264.987671,348.917084 294.869476,352.084015 326.219025,343.116547
          C348.560577,336.725769 367.200867,324.381348 381.629486,306.529877
          C400.865082,282.731079 409.914124,255.314285 407.487823,224.362091
          C406.128418,207.020020 399.950714,191.380920 392.185913,176.168015
          C390.883270,173.615829 389.474792,171.192444 393.984344,169.916779
          C394.662415,168.948166 395.296570,168.765488 396.488678,169.217926
          C399.114166,169.689514 400.456818,171.767715 402.894135,172.582230
          C403.222198,172.772675 403.049927,172.920334 403.138489,172.842468
          C403.574738,173.204315 403.744568,173.704437 403.495483,174.591797
        z"/>
        <path fill="#903035" opacity="1.000000" stroke="none"
          d="
        M244.037415,360.363586
          C250.968323,359.874054 257.266541,363.371887 264.041260,364.856201
          C292.840851,371.165863 320.475372,368.239624 347.484894,356.474030
          C358.750244,351.566742 369.321960,345.553101 378.612396,337.799408
          C388.523224,329.527924 397.557037,320.199982 404.777466,309.236938
          C423.694855,280.513947 431.081696,249.182190 426.703400,215.105988
          C426.069000,210.168518 424.815765,205.303131 423.705109,200.437210
          C423.087799,197.732529 423.570007,195.826172 426.780518,195.007095
          C430.815308,194.843582 431.494354,198.429230 433.847412,200.645355
          C434.160522,200.880692 434.044830,200.930893 434.096619,200.894440
          C434.526245,201.279999 434.659332,201.768677 434.288208,202.626373
          C431.829865,206.230072 431.988525,209.831039 432.564453,213.538742
          C436.410736,238.300079 433.748199,262.362488 424.403503,285.604675
          C417.443726,302.915161 407.594238,318.231079 394.452850,331.749146
          C371.682129,355.172577 344.116913,368.936401 312.160004,373.177856
          C295.918152,375.333527 279.231873,375.635376 263.024780,371.042725
          C260.927795,370.448517 258.794769,370.038513 256.293396,370.973999
          C251.948959,369.156158 249.171646,365.760803 246.199890,362.650940
          C245.572556,361.994507 245.216354,361.034241 244.037415,360.363586
        z"/>
        <path fill="#A82C35" opacity="1.000000" stroke="none"
          d="
        M372.998077,162.679138
          C373.206787,165.262878 374.389709,167.043671 375.729156,168.786240
          C389.750702,187.027664 397.979858,207.819839 398.532074,230.614258
          C399.162598,256.640869 391.140442,280.224884 374.053802,300.680267
          C359.250549,318.402191 340.658813,329.501801 318.814911,335.520599
          C302.468719,340.024567 285.953400,339.800781 269.432098,335.734100
          C255.998459,332.427490 243.658127,326.951691 232.441238,318.845764
          C230.245438,317.258911 228.054718,315.103577 224.755341,316.456360
          C223.066681,317.148773 222.432816,315.842590 222.015640,314.432922
          C221.448990,312.518158 220.802597,310.626984 220.089813,308.371185
          C223.966858,303.605316 227.139847,307.030090 229.707291,309.524048
          C236.531433,316.152954 244.616440,320.697845 253.130173,324.542358
          C277.500885,335.547394 302.183868,336.243744 327.468842,327.343018
          C341.301636,322.473694 353.423065,314.801117 363.689423,304.942413
          C373.759399,295.272278 381.048187,283.097717 386.189697,269.878906
          C397.478027,240.856705 394.969513,212.624756 380.471436,185.525452
          C375.478210,176.192291 368.592834,167.866531 360.583374,160.706467
          C358.699554,159.022446 356.792755,157.433517 356.083740,154.503342
          C358.043060,154.130432 360.088470,154.090759 362.568237,154.114517
          C363.652069,154.672531 364.183258,155.344208 365.319092,155.660126
          C366.205658,156.355103 366.562866,157.148788 367.536621,157.805008
          C368.410278,158.215195 368.940186,158.872681 370.006470,158.430664
          C371.102112,158.325851 371.153595,159.761154 372.274414,159.553955
          C372.673645,160.656555 372.905487,161.491943 372.998077,162.679138
        z"/>
        <path fill="#874A3A" opacity="1.000000" stroke="none"
          d="
        M371.297791,161.061844
          C370.574158,160.501343 370.093109,160.301666 369.358887,160.037140
          C368.566620,159.497299 367.878296,159.513962 367.184387,159.071747
          C366.556183,158.287659 365.972473,157.834518 365.158325,157.227448
          C363.956055,156.548538 364.045197,155.725220 364.561371,154.510910
          C365.166504,154.134216 365.514160,154.100845 366.115784,154.109024
          C366.636047,156.359314 368.237000,156.897293 370.091064,157.516052
          C379.173340,160.547150 388.421814,163.216232 396.744415,168.854385
          C397.190186,169.264267 397.413086,169.420029 397.315125,169.827591
          C396.323730,170.017853 395.653198,169.956299 394.655426,169.864014
          C389.185547,166.507080 383.691010,163.935287 377.495300,162.007111
          C375.277222,161.807709 373.341553,162.448792 371.297791,161.061844
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M335.963196,151.740616
          C335.684387,153.546829 335.015076,154.906937 333.246948,155.898468
          C331.079773,157.212006 329.093018,157.976196 326.381592,157.593933
          C325.279083,157.139175 324.863434,156.669342 324.855713,155.530212
          C326.708008,153.652512 328.763489,153.908707 330.718262,153.858749
          C332.666901,153.808945 334.295319,153.301224 335.581787,151.281082
          C335.857483,151.058762 335.909851,151.516510 335.963196,151.740616
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M364.517883,154.111542
          C364.834961,154.894608 364.851074,155.621643 364.897552,156.711090
          C363.600433,156.832962 363.227478,155.848297 363.037659,154.435760
          C363.407440,154.137131 363.812195,154.096329 364.517883,154.111542
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M443.986237,341.828156
          C443.820343,341.005890 443.867462,340.218048 444.003662,339.263580
          C444.249390,339.768677 444.406036,340.440430 444.594666,341.457825
          C444.626648,341.803467 444.199219,341.862579 443.986237,341.828156
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M227.173660,337.254028
          C227.164886,337.002502 227.582962,337.001678 227.792053,337.001801
          C227.994934,337.187103 227.988708,337.372284 227.971130,337.835358
          C227.700653,337.910675 227.441544,337.708130 227.173660,337.254028
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M458.875183,296.447235
          C458.560547,295.804260 458.122864,294.773376 458.294922,293.856995
          C460.584656,281.663910 459.537415,269.479462 458.372162,256.817596
          C462.334503,269.542358 461.957092,282.807556 458.875183,296.447235
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M446.253357,223.196503
          C445.407104,218.525436 441.947266,215.318497 440.882385,210.718124
          C447.164978,218.038147 450.759003,227.094055 453.921021,236.627457
          C450.042419,233.174118 450.208954,227.243240 446.253357,223.196503
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M434.035645,200.560104
          C431.368835,199.732422 429.930267,196.979080 427.305206,195.113831
          C425.400818,192.566605 423.758484,190.227844 421.989899,187.588150
          C427.203522,190.373047 430.357513,195.491989 434.035645,200.560104
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M421.580811,186.867813
          C419.149780,186.721268 417.495972,184.790939 415.286652,183.121918
          C413.430573,181.450439 411.437958,180.378189 410.368225,177.980530
          C414.701538,179.738998 418.066956,183.157364 421.580811,186.867813
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M449.528320,327.344055
          C450.124054,323.689148 451.798889,320.122437 453.867981,316.353210
          C453.737122,320.174927 452.038300,323.746124 449.528320,327.344055
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M403.560822,175.042816
          C403.234497,174.440338 403.214386,173.962036 403.210663,173.124176
          C405.811951,173.707428 407.996460,175.267563 410.141174,177.425934
          C407.876495,177.578705 405.996704,176.056412 403.560822,175.042816
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M456.186066,251.160446
          C456.129944,249.912994 456.278259,248.876129 456.524750,247.544006
          C457.962158,248.704605 458.409271,250.443954 458.172455,252.668427
          C457.525513,252.431351 456.958008,251.901199 456.186066,251.160446
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M397.279419,170.259140
          C396.994232,170.079422 396.980774,169.593948 396.974030,169.351227
          C399.261810,169.524063 401.025848,170.938431 403.037506,172.503494
          C401.003143,172.802444 399.264832,171.666840 397.279419,170.259140
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M456.784302,306.463379
          C456.207794,304.799286 456.677338,303.079193 457.976440,301.312653
          C458.489990,302.972137 457.956604,304.638336 456.784302,306.463379
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M440.531006,209.806213
          C438.935760,208.962173 437.846893,207.384689 437.183502,205.120911
          C438.815674,205.936432 439.838654,207.568024 440.531006,209.806213
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M454.467773,314.174713
          C454.232941,312.908905 454.562439,311.545135 455.841675,310.243988
          C456.180908,311.553894 455.539032,312.791504 454.467773,314.174713
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M456.126556,243.679443
          C455.067017,242.926422 454.451447,241.668762 454.263824,239.868866
          C455.377655,240.570160 456.013977,241.848099 456.126556,243.679443
        z"/>
        <path fill="#834839" opacity="1.000000" stroke="none"
          d="
        M434.312683,202.968475
          C434.047974,202.468536 434.067261,202.008347 434.117462,201.203064
          C434.630829,201.423157 435.113251,201.988342 435.667175,202.829987
          C435.357941,203.073730 434.977295,203.040985 434.312683,202.968475
        z"/>
        <path fill="#C72532" opacity="1.000000" stroke="none"
          d="
        M257.766968,183.150192
          C258.688080,182.250977 259.421051,181.584732 260.442200,180.830688
          C263.431427,182.652344 265.682800,182.485001 268.722900,180.620667
          C276.710846,175.722092 286.008453,175.447876 294.944366,175.545807
          C313.044434,175.744171 327.952698,183.258286 339.227997,197.528000
          C353.289093,215.323288 356.215576,235.065216 347.988342,256.023346
          C343.394470,267.725830 335.640686,277.373474 324.579071,283.945374
          C314.997650,289.637848 304.393890,293.080658 293.500580,292.849457
          C274.174774,292.439301 258.122589,284.262695 246.382339,268.508179
          C236.372192,255.075363 233.546692,239.864639 235.577606,223.534424
          C236.151825,218.917297 237.146393,214.403183 239.282608,210.172394
          C240.410751,207.938126 240.652115,205.441544 239.873596,202.443878
          C243.531921,195.333588 249.328476,190.615021 254.875244,185.243652
          C255.748016,184.192551 256.932678,184.172623 257.766968,183.150192
        z"/>
        <path fill="#BF2733" opacity="1.000000" stroke="none"
          d="
        M295.765137,161.255463
          C296.517578,160.804031 297.037720,160.577164 297.846313,160.372284
          C300.091858,160.624023 301.756500,159.403641 304.117737,159.382050
          C306.874847,163.129913 310.727539,164.179794 314.442535,165.526428
          C328.630066,170.669220 340.718262,178.459488 350.077545,190.778549
          C361.730103,206.116104 366.708832,222.949875 364.445801,241.943314
          C361.848755,263.740173 351.468231,280.683868 333.085480,292.996155
          C318.903076,302.495178 303.586243,306.484802 286.725555,304.839630
          C266.736084,302.889191 250.357422,294.165985 237.812653,278.403748
          C230.300888,268.965363 225.758118,258.078796 222.396164,246.626251
          C221.975464,245.193115 221.750534,243.700928 220.878052,241.940598
          C220.776840,239.399750 223.850952,237.644577 221.523087,234.855087
          C221.654037,234.132492 221.739563,233.789307 221.998138,233.239960
          C222.484863,232.630234 222.874420,232.321991 223.704544,231.995621
          C225.957596,230.820969 224.055099,228.143112 226.513214,226.952591
          C230.387772,225.707703 230.781281,228.149796 230.756897,230.434250
          C230.649094,240.530807 232.268204,250.288406 236.350845,259.541962
          C240.446701,268.825439 246.844131,276.430084 254.556396,282.868958
          C263.787903,290.576263 274.569305,294.900269 286.494476,296.271271
          C300.939972,297.932068 314.664062,295.167206 326.688538,287.158661
          C347.859680,273.058258 358.492859,253.029434 356.028229,227.411713
          C354.477417,211.291840 346.996246,197.552475 334.661255,186.801636
          C323.749756,177.291443 311.054352,172.093338 296.484772,171.618027
          C293.529755,171.521637 290.387390,171.372223 288.662201,167.550323
          C287.385376,165.629425 288.509094,164.811066 289.723480,163.559204
          C290.822479,162.867096 291.628784,162.542618 292.765015,162.208160
          C293.913422,161.978592 294.831573,162.097443 295.765137,161.255463
        z"/>
        <path fill="#C42632" opacity="1.000000" stroke="none"
          d="
        M239.600311,202.065826
          C241.740662,202.579330 243.375458,201.276047 245.577377,201.137161
          C245.986588,204.011978 244.430023,206.271271 243.215134,208.412796
          C229.813705,232.035873 238.957336,265.071167 261.449280,280.807190
          C279.209412,293.232697 304.111542,293.747742 324.226166,281.447174
          C355.445282,262.355927 359.866089,216.437012 330.160675,190.732391
          C312.747101,175.664108 285.024658,173.165970 265.811737,184.757126
          C261.089142,187.606247 260.915588,184.982269 260.784912,181.119583
          C260.730408,180.742920 260.742981,180.782211 260.753906,180.798889
          C263.474365,180.773254 265.088959,178.941788 266.796143,176.760498
          C266.857117,176.359024 266.838257,176.134888 266.924164,176.062317
          C271.225250,174.771820 273.864105,170.413773 279.033752,170.220215
          C283.822174,172.323853 288.471619,171.410217 292.955811,171.473099
          C303.084442,171.615097 312.654053,173.544739 321.731628,178.214920
          C338.653229,186.920670 349.485901,200.600708 354.448883,218.724289
          C361.063324,242.878601 350.899170,268.169922 332.223022,283.243103
          C323.186523,290.536316 313.068512,294.244568 302.191132,296.064270
          C286.782471,298.642059 272.039185,295.269989 259.347809,286.368561
          C241.165787,273.616150 230.804596,256.210632 231.047073,233.243210
          C231.098434,228.377716 230.832916,223.391403 229.878204,217.979523
          C229.977478,216.794617 230.179993,216.150162 230.570892,215.221741
          C233.797470,212.213562 235.797180,208.764069 237.663910,204.780334
          C238.280807,203.661987 238.817932,202.929337 239.600311,202.065826
        z"/>
        <path fill="#BC2833" opacity="1.000000" stroke="none"
          d="
        M221.816132,239.729248
          C224.959335,244.921906 226.105743,250.525620 227.956207,255.863403
          C242.768280,298.589996 289.381287,314.901642 326.559479,294.591705
          C363.537384,274.391113 374.084045,228.904968 350.591278,194.920975
          C340.367920,180.132187 326.517731,171.346115 309.560577,166.715347
          C306.448090,165.865372 303.122955,165.185410 303.968933,160.333008
          C306.652985,156.998993 310.262756,158.200638 313.883484,158.148529
          C315.561493,160.726151 317.215454,162.179276 319.901031,163.154205
          C336.027740,169.008530 348.019257,180.064621 357.548340,194.048904
          C366.420868,207.069626 369.697968,221.932358 369.178497,237.157623
          C368.579315,254.720062 362.246033,270.684082 350.162689,283.900299
          C334.888306,300.606720 315.789032,309.110626 293.233643,309.463348
          C279.717407,309.674713 267.030884,305.885864 255.407379,299.110870
          C243.829315,292.362274 234.785187,282.971558 228.091599,271.267273
          C225.044815,265.939728 223.061203,260.109802 219.374908,254.755417
          C217.819519,254.465561 217.266953,253.755875 216.843430,252.319611
          C216.700119,251.875015 216.647049,251.436295 216.712921,251.225372
          C218.310120,249.926956 217.764328,248.198868 218.150330,246.412598
          C218.256470,245.623062 218.308823,245.162460 218.484863,244.375610
          C219.219574,242.664932 219.358490,241.075790 220.700623,239.725372
          C221.201874,239.530426 221.417084,239.494476 221.816132,239.729248
        z"/>
        <path fill="#C22633" opacity="1.000000" stroke="none"
          d="
        M229.502319,217.582581
          C233.200058,219.064468 233.738098,221.817078 233.354492,225.337585
          C231.121399,245.831619 237.250107,263.252930 252.163483,277.762299
          C269.599976,294.726410 294.836304,299.118744 317.866272,289.651367
          C340.250702,280.449371 355.781372,254.913345 354.427734,231.101013
          C352.614319,199.200348 325.402496,172.976135 295.084106,173.997482
          C291.427094,174.120682 287.757141,174.058655 284.114868,174.351822
          C281.512970,174.561234 279.511749,174.026184 278.133759,171.272705
          C277.299866,170.177032 277.843475,169.761765 278.705109,169.106323
          C278.932312,168.836731 278.937897,168.901291 278.905701,168.897736
          C280.838623,167.996857 282.985535,167.488693 284.873596,165.803131
          C285.001740,165.454361 285.289703,165.245621 285.465759,165.220612
          C286.564026,165.303238 287.495178,165.339691 288.678894,165.759705
          C292.103851,168.432571 295.833374,169.171768 299.671936,169.624863
          C316.552155,171.617340 330.684723,178.743576 342.103485,191.524551
          C357.263794,208.493362 363.150146,235.632370 353.776611,257.761963
          C347.874176,271.696716 338.571198,282.589142 325.874146,290.027435
          C310.675629,298.931091 294.095459,301.024261 276.755005,296.396515
          C247.499786,288.588989 229.335983,262.106842 228.966415,234.663773
          C228.934875,232.320465 228.886017,229.939880 225.990906,228.006683
          C225.190887,227.071518 225.245575,226.576553 225.526352,225.717743
          C225.936569,222.394058 227.580490,220.058792 229.502319,217.582581
        z"/>
        <path fill="#B92833" opacity="1.000000" stroke="none"
          d="
        M218.641022,253.212097
          C222.313400,252.417389 222.856110,255.092438 223.615829,257.174377
          C226.903412,266.183716 231.654190,274.332397 237.749405,281.702850
          C247.862061,293.931183 261.094666,301.537201 276.125122,305.457184
          C297.854462,311.124237 318.192322,306.461670 336.300659,293.644653
          C355.326202,280.178436 365.587982,261.412048 366.983337,237.979950
          C368.951813,204.923660 348.265289,176.247498 319.167938,165.051254
          C316.541138,164.040512 313.695709,163.185303 313.056946,159.427094
          C312.730164,156.522263 315.188812,156.825577 316.717041,155.700287
          C319.396332,155.354752 321.769440,155.136063 324.585907,154.992371
          C325.209167,155.313278 325.404266,155.546494 325.773010,155.931213
          C326.174866,156.322754 326.667786,156.622757 326.625793,156.766998
          C324.814575,162.987595 330.632660,162.937531 333.585297,164.958099
          C343.918823,172.029648 353.434906,179.940582 360.154083,190.647781
          C371.163910,208.192276 375.558380,227.155777 371.796722,247.776489
          C368.972076,263.260590 362.325562,276.646057 351.447327,288.180481
          C337.360168,303.117432 319.990753,311.213165 299.938171,313.107452
          C276.575134,315.314423 256.365540,307.015533 238.870438,291.887085
          C232.699173,286.550659 228.441910,279.553558 224.239639,272.599854
          C222.482590,269.692413 220.370819,267.023376 216.810852,265.164856
          C215.312668,264.250916 216.345535,262.877655 215.631805,261.713318
          C217.142517,258.820526 217.049362,255.913132 218.641022,253.212097
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M316.636353,155.512787
          C315.844391,156.622391 314.665558,157.671356 313.237427,158.899490
          C310.197693,159.336487 307.407349,159.594345 304.308807,159.924072
          C302.333679,160.177170 300.666687,160.358368 298.567200,160.466919
          C303.759369,157.230896 310.066101,156.567505 316.636353,155.512787
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M214.270142,280.437500
          C215.537048,281.188568 215.884247,282.687164 216.018066,284.620911
          C216.167953,288.735077 217.646088,292.477875 216.123932,296.642059
          C214.066605,291.782898 213.601379,286.391937 214.270142,280.437500
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M278.566589,168.870178
          C278.698029,169.377090 278.463745,169.917450 278.093872,170.719574
          C274.455505,172.418213 271.703735,175.415817 267.375702,175.943604
          C270.140411,172.680222 273.936249,170.421738 278.566589,168.870178
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M237.458801,204.605637
          C237.218887,208.803009 235.428757,212.434738 231.088074,214.820282
          C232.102814,211.060379 234.727600,207.995056 237.458801,204.605637
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M217.346375,260.588013
          C217.024567,261.726288 217.009506,262.485535 216.992188,263.624481
          C217.156586,264.248260 217.323242,264.492371 217.401886,265.171021
          C216.575333,266.343384 215.791428,266.415253 214.722351,265.390747
          C214.185028,262.135681 214.901672,259.401001 215.863342,256.353424
          C219.469986,256.318512 217.488083,258.675354 217.346375,260.588013
        z"/>
        <path fill="#BE2835" opacity="1.000000" stroke="none"
          d="
        M217.276031,260.702942
          C216.809204,259.540894 216.578781,258.114716 216.199982,256.360474
          C216.155945,254.856140 216.260300,253.679886 216.532379,252.189316
          C217.275360,252.156219 217.850586,252.437424 218.713531,252.859009
          C218.879272,255.554108 219.361588,258.229767 217.276031,260.702942
        z"/>
        <path fill="#AB2933" opacity="1.000000" stroke="none"
          d="
        M214.361267,265.402313
          C215.097183,265.001434 215.708099,264.987457 216.624542,264.964233
          C219.683563,266.310120 219.691544,268.090576 217.373703,270.588013
          C217.008713,271.001221 216.514633,271.008728 216.267593,271.011963
          C215.151917,270.947876 214.996063,270.215454 214.527817,269.308777
          C214.311218,267.936493 214.273727,266.862823 214.361267,265.402313
        z"/>
        <path fill="#C72532" opacity="1.000000" stroke="none"
          d="
        M266.492706,176.417160
          C266.252838,179.201172 264.760406,181.014648 261.182861,180.856400
          C262.065155,178.766373 263.960571,177.452560 266.492706,176.417160
        z"/>
        <path fill="#C42734" opacity="1.000000" stroke="none"
          d="
        M222.196320,239.750946
          C222.000000,240.000000 221.586288,239.980286 221.379410,239.970703
          C220.888840,239.770782 220.605148,239.580429 220.411499,238.885620
          C220.451660,238.075577 220.473709,237.775299 220.664124,237.274323
          C220.912552,236.424271 221.095657,235.791168 221.411285,234.875885
          C221.407120,234.545639 221.452957,234.430130 221.510742,234.452911
          C223.161911,235.934860 223.908432,237.532898 222.196320,239.750946
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M284.630981,165.503540
          C283.862640,167.619324 282.078339,168.769104 279.250458,168.882156
          C280.019287,166.732925 282.357941,166.494553 284.630981,165.503540
        z"/>
        <path fill="#C42734" opacity="1.000000" stroke="none"
          d="
        M226.007614,228.021912
          C225.898483,229.669022 225.678772,231.268753 224.194168,232.746628
          C223.481308,232.487823 223.182480,231.900650 223.166321,230.732849
          C223.434982,229.662582 223.595291,229.084213 223.942703,228.267670
          C224.447525,227.572205 224.880066,227.394699 225.711548,227.747559
          C225.995682,227.998108 226.003128,228.011948 226.007614,228.021912
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M289.078705,165.705856
          C288.012146,165.825134 287.071960,165.673401 285.886780,165.358643
          C286.894989,164.547058 288.148193,163.898483 289.708801,163.220734
          C289.745850,163.939316 289.475464,164.687073 289.078705,165.705856
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M220.250977,238.617828
          C220.618652,238.642136 220.735779,238.903122 221.012726,239.562622
          C220.455505,241.184799 219.738464,242.408478 218.814987,243.840759
          C218.169083,242.075684 218.898102,240.415085 220.250977,238.617828
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M217.968109,246.373016
          C218.875534,247.795181 218.604080,249.311157 217.022491,250.810211
          C216.644775,249.464813 216.997284,248.021729 217.968109,246.373016
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M214.244781,269.403931
          C214.811249,269.530426 215.273788,270.050751 215.878448,270.793121
          C215.613022,271.568207 215.205505,272.121216 214.602417,272.842224
          C214.318207,271.939331 214.229553,270.868530 214.244781,269.403931
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M295.672974,161.029785
          C295.456757,161.965500 294.685059,162.461700 293.333252,162.253754
          C293.846130,161.808334 294.597321,161.418503 295.672974,161.029785
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M213.921204,275.249756
          C214.099915,275.850922 214.154053,276.679382 214.116211,277.746033
          C213.948364,277.148499 213.872513,276.312744 213.921204,275.249756
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M257.655579,182.985886
          C257.221100,183.579651 256.487091,184.242065 255.459091,184.988403
          C255.543304,184.017441 256.419159,183.502869 257.655579,182.985886
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M225.931808,227.550995
          C225.580978,228.017242 225.166260,228.036377 224.440674,228.042511
          C224.441086,227.271759 224.752380,226.514038 225.291779,225.542236
          C225.635880,225.920074 225.751907,226.511978 225.931808,227.550995
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M223.081451,230.478989
          C223.439041,230.848221 223.603409,231.455505 223.861084,232.524536
          C223.558319,233.007751 223.162247,233.029205 222.468674,233.042221
          C222.410202,232.261536 222.649216,231.489288 223.081451,230.478989
        z"/>
        <path fill="#A22C34" opacity="1.000000" stroke="none"
          d="
        M221.224548,234.693817
          C221.293304,235.192093 221.179504,235.838547 220.913116,236.776672
          C220.854370,236.326218 220.948196,235.584106 221.224548,234.693817
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M371.209381,160.967468
          C372.850189,160.987823 374.645203,161.274643 376.797974,161.718567
          C376.007141,162.219696 374.858551,162.563705 373.354370,162.955399
          C372.453705,162.413376 371.908630,161.823669 371.209381,160.967468
        z"/>
        <path fill="#B72834" opacity="1.000000" stroke="none"
          d="
        M217.359100,271.073608
          C216.991470,269.274811 216.974243,267.548431 216.943542,265.388489
          C216.930084,264.954987 216.959808,264.479492 216.974884,264.241821
          C220.444244,262.242950 222.098175,263.480499 223.806198,266.923523
          C237.992172,295.519409 261.583923,310.815643 293.093536,311.308044
          C319.594055,311.722168 341.484924,300.076965 356.963501,278.122406
          C373.866333,254.147720 375.537567,228.051666 363.959991,201.772827
          C355.962128,183.619324 341.996277,170.231628 323.296082,162.440018
          C322.375427,162.056412 321.394165,161.813278 320.487732,161.401978
          C319.598114,160.998306 318.516388,160.557190 318.774536,159.312119
          C318.970856,158.365158 319.951294,158.242966 320.772339,158.117020
          C322.417938,157.864578 324.098511,157.736649 325.678101,156.320801
          C327.984253,156.079620 330.037109,156.063828 332.524292,156.055725
          C330.162506,159.363831 331.425659,161.655746 334.646790,163.742493
          C342.622101,168.909210 350.320526,174.379486 356.380280,181.908966
          C380.248901,211.566711 381.650299,252.566895 358.767792,282.955475
          C346.346558,299.451202 329.428986,309.647003 308.871979,313.709503
          C280.332092,319.349579 256.447937,309.989258 235.359451,291.499695
          C230.731033,287.441681 227.457123,282.071747 224.337830,276.732178
          C222.788895,274.080719 221.020462,271.701294 217.359100,271.073608
        z"/>
        <path fill="#AB2A34" opacity="1.000000" stroke="none"
          d="
        M367.248474,158.909714
          C367.643219,159.031128 368.146545,159.321442 368.877777,159.792023
          C368.522797,159.674393 367.939911,159.376511 367.248474,158.909714
        z"/>
        <path fill="#CC2432" opacity="1.000000" stroke="none"
          d="
        M294.956909,183.004578
          C330.396179,184.089188 353.516327,219.743103 341.893677,250.902161
          C332.238831,276.785797 303.604431,291.960846 276.414124,281.979340
          C247.109116,271.221497 233.348740,238.183060 248.731964,209.771103
          C258.521698,191.689987 274.121307,183.187820 294.956909,183.004578
        z"/>
        <path fill="#D12232" opacity="1.000000" stroke="none"
          d="
        M306.320435,275.492493
          C278.721130,281.714081 255.026871,266.393463 250.502518,240.014999
          C247.326736,221.499222 260.747559,197.248001 283.039673,191.966492
          C304.286011,186.932770 327.543640,199.187134 334.214935,219.336273
          C341.190796,240.405243 333.768372,260.624451 314.592438,272.303772
          C312.222168,273.747375 309.354828,274.374878 306.320435,275.492493
        z"/>
        <path fill="#D72131" opacity="1.000000" stroke="none"
          d="
        M316.251770,207.743561
          C330.072754,217.055618 331.260773,241.062164 322.695435,253.153046
          C312.338440,267.772949 294.476654,273.379822 278.217438,265.439117
          C265.672424,259.312317 258.684814,248.505798 258.307373,234.735672
          C257.946625,221.574188 263.910492,210.895950 275.710236,204.208618
          C289.812347,196.216446 305.174438,197.555603 316.251770,207.743561
        z"/>
        <path fill="#DE1F31" opacity="1.000000" stroke="none"
          d="
        M301.526733,258.781128
          C289.714478,261.731842 279.651672,259.297363 272.704010,249.600861
          C266.165863,240.475830 266.011353,230.293304 271.408875,220.375793
          C276.973907,210.150574 289.795258,205.576614 301.687714,209.263138
          C312.794128,212.706009 320.242615,223.829971 319.622528,235.214066
          C318.971924,247.158279 312.736145,254.562073 301.526733,258.781128
        z"/>
        <path fill="#E61D31" opacity="1.000000" stroke="none"
          d="
        M294.659180,218.438171
          C308.612549,222.139221 313.494293,234.991333 304.921539,244.477249
          C300.412933,249.466110 294.898499,250.828720 288.654755,248.496780
          C282.004120,246.012878 278.110962,240.989349 278.079041,234.850479
          C278.041260,227.575027 281.663483,221.950775 287.947540,219.578049
          C289.979156,218.810959 292.055908,218.218948 294.659180,218.438171
        z"/>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
